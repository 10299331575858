import axios from "../api/axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
const ReservationModal = ({
  reservation,
  reservations,
  setOpenModal,
  setReservations,
}) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [editOrderData, setEditOrderData] = useState({
    orderId: reservation.orderId,
    tourStatus: "",
    notes: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    setEditOrderData({
      orderId: reservation.orderId,
      [e.target.name]: e.target.value,
    });
    console.log(editOrderData);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const orderId = editOrderData.orderId;
    try {
      const response = await axios.delete(`/reservations/delete/${orderId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        withCredentials: true,
      });
      setEditOrderData({
        orderId: "",
        tourStatus: "",
        notes: "",
      });
      const allReservations = [...reservations, response.data];
      setReservations(allReservations);
      setOpenModal(false);
      navigate("/reservations", { replace: "true" });
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const orderId = editOrderData.orderId;
    try {
      const response = await axios.patch(
        `/reservations/edit/${orderId}`,
        JSON.stringify(editOrderData),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.accessToken}`,
          },
          withCredentials: true,
        }
      );
      setEditOrderData({
        orderId: "",
        tourStatus: "",
        notes: "",
      });
      const allReservations = [...reservations, response.data];
      setReservations(allReservations);
      setOpenModal(false);
      navigate("/reservations", { replace: "true" });
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  };

  try {
    return (
      <tr>
        <td>
          <div className="modalBackground">
            <div className="modalContainer">
              <div className="modalBody">
                <h3>Manuel Antonio AdvenTour</h3>
                <p><b>Order Id: </b>{reservation.orderId}</p>
                <p><b>Created At: </b>{reservation.createdAt}</p>
                <p><b>Status: </b>{reservation.tourStatus}</p>
                <br />
                <p><b>Tour: </b>{reservation.tour}</p>
                <p><b>Service: </b>{reservation.tourType}</p>            
                <p><b>Tour Date: </b>{reservation.tourDate}</p>
                <p><b>Pax Amount: </b>{reservation.tourist.length}</p>
                <br />
                
                <p><b>Tourist Information</b></p>
                <hr />
                {reservation.tourist.map((client) => (
                  <div key={client._id}>
                    <br />
                    <p><b>ID / Passport: </b>{client.clientId}</p>
                    <p><b>Name: </b>{client.client}</p>
                    <p><b>Country: </b>{client.country}</p>
                    <p><b>Phone: </b>{client.phone}</p>
                    <p><b>Email: </b>{client.email}</p>
                    <br />
                  </div>
                ))}
                <p><b>Payer: </b>{reservation.payer}</p>
                <p><b>Payer Email: </b>{reservation.email}</p>
                <br />
                <p><b>Unit Price: </b>${reservation.unitPrice}</p>
                <hr />
                <p><b>Total Price: </b>${reservation.totalPrice}</p>
                <br />
                <p><b>Notes: </b>{reservation.notes} </p>
              </div>
              {reservation.orderStatus === "COMPLETED" && (
                <form className="editOrderForm">
                  <textarea
                    type="text"
                    id="notes"
                    name="notes"
                    placeholder="Notes..."
                    value={editOrderData.notes}
                    onChange={handleChange}
                  />
                  <div className="buttonContainer">
                    <label className="hiddenLabel" htmlFor="tourStatus">
                      Status:
                    </label>
                    <select
                      value={editOrderData.tourStatus}
                      onChange={handleChange}
                      name="tourStatus"
                      id="tourStatus"
                    >
                      <option value="" disabled defaultValue={""}>
                        Select...
                      </option>
                      <option value="Scheduled">Scheduled</option>
                      <option value="Completed">Completed</option>
                      <option value="Pending">Pending</option>
                    </select>

                    <input
                      type="button"
                      className="save-btn"
                      onClick={handleEditSubmit}
                      value={"Save"}
                    />

                    <input
                      type="button"
                      className="close-btn"
                      value={"Back"}
                      onClick={() => setOpenModal(false)}
                    />
                  </div>
                </form>
              )}
                            {reservation.orderStatus === "pending" && (
                <form className="editOrderForm">
                
                  <div className="buttonContainer">
                 
                    <input
                      type="button"
                      className="del-btn"
                      onClick={handleDelete}
                      value={"Delete"}
                    />

                    <input
                      type="button"
                      className="close-btn"
                      value={"Back"}
                      onClick={() => setOpenModal(false)}
                    />
                  </div>
                </form>
              )}
            </div>
          </div>
        </td>
      </tr>
    );
  } catch (error) {
    console.log(error);
  }
};

export default ReservationModal;
