import useAxiosFetch from "../hooks/useAxiosFetch";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
const Dashboard = ({ tours }) => {
  const [reservations, setReservations] = useState([]);
  const navigate = useNavigate();
  const DataUrl = process.env.REACT_APP_SERVER + "/reservations";
  const { data } = useAxiosFetch(DataUrl);

  const date = new Date();
  let day = date.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  let thisMonth = date.getMonth() + 1;
  if (thisMonth < 10) {
    thisMonth = "0" + thisMonth;
  }

  let year = date.getUTCFullYear();
let today = `${year}-${thisMonth}-${day}`;
console.log(today)





  const thisMonthReservation = () => {
    return reservations.filter(
      (reservation) =>
        reservation.orderStatus === "COMPLETED" &&
        reservation.createdAt.split("-", 2)[1] === `${thisMonth}`
    ).length;
  };

  const lastMonthReservation = () => {
    return reservations.filter(
      (reservation) =>
        reservation.orderStatus === "COMPLETED" &&
        reservation.createdAt.split("-", 2)[1] === `${thisMonth - 1}`
    ).length;
  };




  const thisMonthIncome = () => {
    return reservations
      .filter(
        (reservation) =>
          reservation.orderStatus === "COMPLETED" &&
          reservation.createdAt.split("-", 2)[1] === `${thisMonth}`
      )
      .reduce((sum, reservation) => sum + reservation.totalPrice, 0);
  };

  const lastMonthIncome = () => {
    return reservations
      .filter(
        (reservation) =>
          reservation.orderStatus === "COMPLETED" &&
          reservation.createdAt.split("-", 2)[1] === `${thisMonth - 1}`
      )
      .reduce((sum, reservation) => sum + reservation.totalPrice, 0);
  };

  useEffect(() => {
    setReservations(data);
  }, [data]);

  return (
    <>
      {tours && reservations && (
        <section className="Dashboard">
          <div className="mainPanel">
            <h3 className="h3Price">Dashboard</h3>
            <div className="cardContainer">
              <div
                className="dashboardCard1"
                onClick={() => navigate("/reservations")}
              >
                <h3>Reservations Sold</h3>
               
                <p>
                  <b>This Month: </b>
                  {thisMonthReservation()}
                </p>
                <p>
                  <b>Last Month: </b>
                  {lastMonthReservation()}
                </p>
                <p>
                  <br />
                  <b>Global: </b>
                  {
                    reservations.filter(
                      (reservation) => reservation.orderStatus === "COMPLETED"
                    ).length
                  }
                </p>
              </div>
              <div
                className="dashboardCard1"
                onClick={() => navigate("/reservations")}
              >
                <h3>Reservations Status</h3>
                <p>
                  <b>New: </b>
                  {
                    reservations.filter(
                      (reservation) =>
                        reservation.tourStatus === "New" &&
                        reservation.orderStatus === "COMPLETED"
                    ).length
                  }
                </p>
                <p>
                  <b>Scheduled: </b>
                  {
                    reservations.filter(
                      (reservation) =>
                        reservation.tourStatus === "Scheduled" &&
                        reservation.orderStatus === "COMPLETED"
                    ).length
                  }
                </p>
                <p>
                  <b>Pending: </b>
                  {
                    reservations.filter(
                      (reservation) =>
                        reservation.orderStatus === "COMPLETED" &&
                        reservation.tourStatus === "Pending"
                    ).length
                  }
                </p>
                <p>
                  <b>Completed: </b>
                  {
                    reservations.filter(
                      (reservation) =>
                        reservation.orderStatus === "COMPLETED" &&
                        reservation.tourStatus === "Completed"
                    ).length
                  }
                </p>

                <p>
                  <b>Unfinished: </b>
                  {
                    reservations.filter(
                      (reservation) => reservation.orderStatus === "pending"
                    ).length
                  }
                </p>
              </div>
              <div className="dashboardCard1">
                <h3>Income</h3>
            
                <p>
                  <b>This Month: $</b>
                  {thisMonthIncome()}
                </p>
                <p>
                  <b>Last Month: $</b>
                  {lastMonthIncome()}
                </p>

                <p>
                  <br />

                  <b>Total: $</b>
                  {reservations
                    .filter(
                      (reservation) => reservation.orderStatus === "COMPLETED"
                    )
                    .reduce(
                      (sum, reservation) => sum + reservation.totalPrice,
                      0
                    )}
                </p>
              </div>
            </div>
            <div className="tableContainer">
              <table>
                <caption>Tours Overview</caption>
                <thead>
                  <tr>          
                    <th>Tour</th>
                    <th>$ Regular</th>
                    <th>$ Private</th>
                    <th>Active</th>
                    <th>Total Sales </th>
                    <th>Regular Pax</th>
                    <th>Private Pax</th>            
                    <th>Total Pax</th>
                    <th>Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  {tours?.map((tour) => (
                    <tr
                      className="reservation"
                      key={tour._id}
                      onClick={() => navigate(`/tour/${tour._id}`)}
                    > 
                      <td data-cell="Tour"> {tour.title} </td>
                      <td data-cell="$ Regular ">${tour.unitPrice}</td>
                      <td data-cell="$ Private">${tour.privatePrice} </td>
                      <td data-cell="Active"> {tour.active} </td>
                      <td data-cell="Total Sales">
                        {
                          reservations.filter(
                            (reservation) =>
                              reservation.tour === tour.title &&
                              reservation.orderStatus === "COMPLETED"
                          ).length
                        }
                      </td>
                      <td data-cell="Regular Pax">
                        {reservations
                          .filter(
                            (reservation) =>
                              reservation.tour === tour.title &&
                              reservation.orderStatus === "COMPLETED" &&
                              reservation.tourType === "regular"
                          )
                          .reduce(
                            (sum, reservation) =>
                              sum + reservation.tourist.length,
                            0
                          )}
                      </td>
                      <td data-cell="Private Pax">
                        {reservations
                          .filter(
                            (reservation) =>
                              reservation.tour === tour.title &&
                              reservation.orderStatus === "COMPLETED" &&
                              reservation.tourType === "private"
                          )
                          .reduce(
                            (sum, reservation) =>
                              sum + reservation.tourist.length,
                            0
                          )}
                      </td>
                      <td data-cell="Total Pax">
                        {reservations
                          .filter(
                            (reservation) =>
                              reservation.tour === tour.title &&
                              reservation.orderStatus === "COMPLETED"
                          )
                          .reduce(
                            (sum, reservation) =>
                              sum + reservation.tourist.length,
                            0
                          )}
                      </td>
                      <td data-cell="Revenue"> $
                      {reservations
                          .filter(
                            (reservation) =>
                              reservation.tour === tour.title &&
                              reservation.orderStatus === "COMPLETED"
                          )
                          .reduce(
                            (sum, reservation) =>
                              sum + reservation.totalPrice,
                            0
                          )}

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Dashboard;
