import React from 'react'

const SearchBar = ({search, setSearch}) => {
  return (
    <form className='searchForm' onSubmit={(e)=> e.preventDefault()}>
        <label className='hiddenLabel' htmlFor="search">Search</label>
        <input 
          id="search"
          type="text"
          placeholder="🔍 Search ..."
          autoComplete="off"
          value={search}
          onChange={(e)=> setSearch(e.target.value)}
           /> 
      </form>
    
  )
}

export default SearchBar