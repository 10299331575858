import React from "react";

const Terms = () => {
  return (
    <section className="Terms">
      <div className="mainPanel">


      <h3 className="h3Price">Terms</h3>
      <br />
      <br />
      <div className="textContainer">
        <br />
        <h3>Refund Policy</h3>
        <p>
          Full refund by canceling 8 days in advance.
          <br />
          50% refund by canceling 7 to 1 days in advance.
          <br />
          24 hours or less, NO refundable.
        </p>

        <h3>Holidays</h3>
        <small>
       
          <i> *(Easter, Thanksgiving, Christmas and New Years Eve)</i>
        </small>
        <br />
        <br />
        <p>
          Full refund by canceling 15 or more days in advance.
          <br />
          14 days or less NO refundable.
        </p>
      </div>      
      </div>
    </section>
  );
};

export default Terms;
