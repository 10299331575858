import { useNavigate, useParams } from "react-router-dom";
import Missing from "./Missing";
import useAuth from "../hooks/useAuth";

const TourPage = ({ tours }) => {
  const navigate = useNavigate();
  const goBacK = () => {
    navigate(-1);
  };

  const goBooking = () => {
    navigate(`/booking/${id}`);
  };

  const goEditing = () => {
    navigate(`/edit/${id}`);
  };

  const { auth } = useAuth();
  const { id } = useParams();
  const tour = tours.find((tour) => tour._id.toString() === id);
  return (
    <section className="TourPage">
      {!auth.username && tour && tour.active === "true" && (
        <>
          <div className="tourFullImage">
            <img src={tour.imageUrl} alt="img" />
          </div>

          <div className="tourFullInfo">
            <h2>{tour.title}</h2>
           
            <div className="textContainer">
              <p>{tour.description}</p>
            </div>
            <div className="textContainer">
              <p><b>Time: </b>{tour.duration} hours</p>
            </div>
            <div className="textContainer">
              <p><b>Included: </b>{tour.included}</p>
            </div>

            <div className="textContainer">
              <p><b>Location: </b>{tour.location}</p>
            </div>
            <div className="textContainer">
              <p><b>Recommendations: </b>{tour.recommendation}</p>
            </div>
            

            <h3 className="h3Price">
              Regular: ${tour.unitPrice} / Private: ${tour.privatePrice}
            </h3>
            <div className="buttonContainer">
            {tour.title ==="Manuel Antonio National Park" &&(
              <><h4>⚠️ CLOSED ON TUESDAYS! ⚠️</h4></>
            )}
            </div>
            <div className="buttonContainer">
              <button type="button" className="buyButton" onClick={goBooking}>
                Book Now!
              </button>

              <button className="backButton" type="button" onClick={goBacK}>
                Go Back
              </button>
            </div>
          </div>
        </>
      )}
      {auth.username && tour && (
        <>
          <div className="tourFullImage">
            <img src={tour.imageUrl} alt="img" />
          </div>
          <div className="tourFullInfo">
            <h2> {tour.title}</h2>

            <h2>Active = {tour.active} </h2>
          
            <div className="textContainer">
              <p>{tour.description}</p>
            </div>
            <div className="textContainer">
              <p><b>Time: </b>{tour.duration} hours</p>
            </div>
            <div className="textContainer">
              <p><b>Included: </b>{tour.included}</p>
            </div>

            <div className="textContainer">
              <p><b>Location: </b>{tour.location}</p>
            </div>
            <div className="textContainer">
              <p><b>Recommendations: </b>{tour.recommendation}</p>
            </div>

            <h3 className="h3Price">
              Regular: ${tour.unitPrice} / Private: ${tour.privatePrice}
            </h3>
            <div className="buttonContainer">
              <button type="button" className="buyButton" onClick={goEditing}>
                Edit
              </button>

              <button className="backButton" type="button" onClick={goBacK}>
                Go Back
              </button>
            </div>
          </div>
        </>
      )}
      {!tour && <Missing />}
    </section>
  );
};

export default TourPage;
