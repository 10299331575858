import axios from "../api/axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const navigate = useNavigate();
  const [emailData, setEmailData] = useState({
    email: "",
    subject: "",
    emailBody: "",
  });
  /*
  const goBacK = () => {
    navigate(-1);
  };
*/
  const handleChange = (e) => {
    e.preventDefault();
    setEmailData({
      ...emailData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEmailForm = (e) => {
    e.preventDefault();
    sendEmailForm();
    alert("message sent");
    navigate("/");
  };

  const sendEmailForm = async () => {
    try {
      await axios.post("contact/send-request", JSON.stringify(emailData), {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setEmailData({
        email: "",
        subject: "",
        emailBody: "",
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <section className="Contact">
      <div className="mainPanel">
        <form className="emailForm" onSubmit={handleEmailForm}>
          <h3 className="h3Price">Contact</h3>
          <div className="textContainer">
            <p>
              For more information, suggestions or reservations assistance, feel
              free to contact us!
            </p>{" "}
          </div>
          <label className="hiddenLabel" htmlFor="title">
            Email
          </label>
          <input
            name="email"
            type="email"
            required
            placeholder="Your email address..."
            value={emailData.email}
            onChange={handleChange}
          />

          <label className="hiddenLabel" htmlFor="subject">
            Subject
          </label>
          <input
            name="subject"
            type="text"
            required
            placeholder="Subject"
            value={emailData.subject}
            onChange={handleChange}
          />

          <label className="hiddenLabel" htmlFor="emailBody">
            Email Body
          </label>
          <textarea
            name="emailBody"
            required
            placeholder="Type here your request..."
            value={emailData.emailBody}
            onChange={handleChange}
          />

          <div className="buttonContainer">
            <button type="submit" className="saveButton">
              Send
            </button>
          </div>
        </form>
        <div className="textContact">
          <h3 className="h3Price">Management</h3>

          <div className="buttonContainer ">
            <img className="whatsapp" src="/img/whatsapp.png" alt="" />{" "}
            <a rel="noopener noreferrer"
             href="https://wa.me/50686598767"
             target="_blank"> (+506) 8659 8767</a>
          </div>
          <div className="buttonContainer">
            <img className="instagram" src="/img/instagram.png" alt="" />
            <a rel="noopener noreferrer"
             href="https://www.instagram.com/manuelantonioadventourcr" 
             target="_blank">Follow us on Instagram</a>
          </div>
          <div className="buttonContainer">
            <img className="mail" src="/img/mail.png" alt="" />
            <a href="mailto:infoadventourma@gmail.com">infoadventourma@gmail.com</a>
          </div>

          <div className="buttonContainer">
            <img className="tripadvisor" src="/img/TripAdvisor.png" alt="" />
            <a
              rel="noopener noreferrer"
              href="https://www.tripadvisor.com/Attraction_Review-g309274-d26872823-Reviews-Manuel_Antonio_AdvenTour-Manuel_Antonio_Quepos_Province_of_Puntarenas.html"
              target="_blank"
            >
              Manuel Antonio AdvenTour
            </a>
          </div>
          <div className="buttonContainer">
            <img className="location" src="img/location.png" alt="" />
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://maps.app.goo.gl/UXBekMcJfRzKF1w4A"
            >{`Manuel Antonio, Costa Rica  🇨🇷`}</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
