import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";

const EditUser = () => {
  const {auth} = useAuth();
  const navigate = useNavigate();
  const userRef = useRef();
  const errRef = useRef();
  const [editUserData, setEditUserData]= useState({
    username: "",
    password: "",
    newUsername: "",
    newPassword: ""
  })

  const clearForm=()=>{
    setEditUserData({
      username: "",
      password: "",
      newUsername: "",
      newPassword: ""
    });
  }

  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [editUserData]);

  const handleChange = (e) => {
    e.preventDefault();
    setEditUserData({
      ...editUserData,
      [e.target.name]: e.target.value,
    });
   // console.log(e.target.value);
  };


  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
       await axios.patch(
        "/user",
        JSON.stringify(editUserData),
        {
                  headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        withCredentials: true,
      });

      clearForm();
      navigate("/dashboard");

    } catch (err) {
      if (!err?.response) {
        setErrMsg("No server response...");
        clearForm();
      } else if (err.response?.status === 400) {
        setErrMsg("Missing username or password");
        clearForm();
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        clearForm();
      } else {
        setErrMsg("Failed to update credentials");
        clearForm();
      }
      errRef.current.focus();
    }
  };

  return (
    <section className="Login"> {/* Change className*/}
    <div className="mainPanel">
      <p ref={errRef} className={errMsg ? "errMsg" : "offscreen"}>
        {errMsg}
      </p>
      <h3 className="h3Price">Edit User</h3>
      <br />
      <form className="loginForm" onSubmit={handleEditSubmit}>
        <label className="hiddenLabel" htmlFor="username">
          Username
        </label>
        <input
          name="username"
          type="text"
          ref={userRef}
          autoComplete="off"
          placeholder="Current Username"
          required
          value={editUserData.username}
          onChange={handleChange}
        />

        <label className="hiddenLabel" htmlFor="password">
          Password
        </label>
        <input
          name="password"
          type="password"
          required
          autoComplete="off"
          placeholder="Current Password"
          value={editUserData.password}
          onChange={handleChange}
        />
        <br />
        <br />

                <label className="hiddenLabel" htmlFor="username">
         New Username
        </label>
        <input
         name="newUsername"
          type="text"
          autoComplete="off"
          placeholder="New Username"
          value={editUserData.newUsername}
          onChange={handleChange}
        />

        <label className="hiddenLabel" htmlFor="password">
          New Password
        </label>
        <input
          name="newPassword"
          type="password"
          autoComplete="off"
          placeholder="New Password"
          value={editUserData.newPassword}
          onChange={handleChange}
        />
        <div className="buttonContainer">
          <button className="buyButton" type="submit">
            Confirm
          </button>
        </div>
      </form>
      </div>
    </section>
  );
};

export default EditUser;
