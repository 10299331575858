import { useEffect, useState } from "react";
import FeedReservation from "../components/FeedReservation";
import SearchBar from "../components/SearchBar";
import useAxiosFetch from "../hooks/useAxiosFetch";

const Reservations = () => {
  const [reservations, setReservations] = useState([]);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [orderStatus, setOrderStatus] = useState("COMPLETED");
  const DataUrl = process.env.REACT_APP_SERVER + "/reservations";
  const { data, fetchError, isLoading } = useAxiosFetch(DataUrl);

  const handleChange = (e) => {
    e.preventDefault();
    setOrderStatus(e.target.value);
    console.log(e.target.value);
  };

  useEffect(() => {
    setReservations(data);
  }, [data]);

  useEffect(() => {
    try {
      if (reservations) {
        reservations.sort(function (a, b) {
          return a.createdAt > b.createdAt;
        });
        const filterResults = reservations.filter(
          (reservation) =>
            reservation?.orderId
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            reservation?.payer?.toLowerCase().includes(search.toLowerCase()) ||
            reservation?.tour?.toLowerCase().includes(search.toLowerCase()) ||
            reservation?.tourStatus
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            reservation?.tourDate?.includes(search) ||
            reservation?.tourType?.toLowerCase().includes(search.toLowerCase())
        );

        setSearchResults(filterResults.reverse());
      } else {
        console.log("Loading");
      }
    } catch (err) {
      console.error("error here: ", err);
    }
  }, [reservations, search]);

  return (
    <section className="Reservations">
      <div className="mainPanel">
        <h3 className="h3Price">Reservations</h3>
        <SearchBar search={search} setSearch={setSearch} />

        <label htmlFor="orderStatus" className="labelSelect">
          Order Status:
          <select
            value={orderStatus}
            id="orderStatus"
            name="orderStatus"
            onChange={handleChange}
          >
            <option value={"COMPLETED"}>Completed</option>
            <option value={"pending"}>Unfinished</option>
          </select>
        </label>
        {isLoading && <p>Loading reservations...</p>}
        {!isLoading && fetchError && <p>{fetchError}</p>}
        {!isLoading &&
          !fetchError &&
          (reservations.length ? (
            <FeedReservation
              reservations={searchResults}
              setReservations={setReservations}
              orderStatus={orderStatus}
            />
          ) : (
            <>
              <br />
              <h3 className="h3Price">No reservations available</h3>
            </>
          ))}
      </div>
    </section>
  );
};

export default Reservations;
