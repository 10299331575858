import { useNavigate } from "react-router-dom";

const Missing = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <section className="Missing">


     
      <h3 className="h3Price">Page Not Found</h3>
      <button type="button" className="backButton" onClick={goBack}>
        Go Back
      </button> 
 
    </section>
  );
};

export default Missing;
