import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const userRef = useRef();
  const errRef = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "/auth",
        JSON.stringify({ username, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const accessToken = response?.data?.accessToken;
      setAuth({ username, password, accessToken });
      setUsername("");
      setPassword("");
      navigate("/dashboard");
      //navigate(from, {replace: true});
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No server response...");
        setUsername("");
        setPassword("");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing username or password");
        setUsername("");
        setPassword("");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        setUsername("");
        setPassword("");
      } else {
        setErrMsg("Failed to login");
        setUsername("");
        setPassword("");
      }
      errRef.current.focus();
    }
  };

  return (
    <section className="Login">
      <div className="mainPanel">

     
      <p ref={errRef} className={errMsg ? "errMsg" : "offscreen"}>
        {errMsg}
      </p>
      <h3 className="h3Price">Login</h3>
      <br />
      <form className="loginForm" onSubmit={handleLoginSubmit}>
        <br />
        <label className="hiddenLabel" htmlFor="username">
          Username
        </label>
        <input
          name="username"
          type="text"
          ref={userRef}
          autoComplete="off"
          placeholder="Username"
          required
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <label className="hiddenLabel" htmlFor="password">
          Password
        </label>
        <input
          name="password"
          type="password"
          required
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="buttonContainer">
          <button className="buyButton" type="submit">
            Log In
          </button>
        </div>
      </form>
      </div>
    </section>
  );
};

export default Login;
