import FeedTour from "../components/FeedTour";
import SearchBar from "../components/SearchBar";

const Home = ({ tours, fetchError, isLoading, search, setSearch }) => {
  return (
    <section className="Home">
      <br />
      <h2 className="h2Home">Check our activities below</h2>
      <SearchBar search={search} setSearch={setSearch} />
      <div className="tourContainer">
        {isLoading && <h3 className="h3Price">Loading tours...</h3>}
      {!isLoading && fetchError && <h3 className="h3Price">{fetchError}</h3>}
      {!isLoading &&
        !fetchError &&
        (tours.length ? (
          <FeedTour tours={tours} />
        ) : (
          <>
          <h3 className="h3Price">No tours to display</h3>
          </>

        ))}
      </div>
      
    </section>
  );
};

export default Home;
