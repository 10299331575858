import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Missing from "./Missing";

const EditTour = ({ tours, setTours, dataUrl, refetchData }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const { id } = useParams();
  const tour = tours.find((tour) => tour._id.toString() === id);
  const [editTourData, setEditTourData] = useState({
    title: "",
    location: "",
    duration: "",
    included: "",
    imageUrl: "",
    description: "",
    recommendation: "",
    unitPrice: "",
    privatePrice: "",
    active: "",
  });

  useEffect(() => {
    if (tour) {
      setEditTourData({
        id: tour._id,
        title: tour.title,
        location: tour.location,
        duration: tour.duration,
        included: tour.included,
        imageUrl: tour.imageUrl,
        description: tour.description,
        recommendation: tour.recommendation,
        unitPrice: tour.unitPrice,
        privatePrice: tour.privatePrice,
        active: tour.active,
      });
    }
  }, [tour, setEditTourData]);

  const handleChange = (e) => {
    e.preventDefault();
    setEditTourData({
      ...editTourData,
      [e.target.name]: e.target.value,
    });
    //console.log(e.target.value, editTourData.active);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const id = editTourData.id;
    try {
      const response = await axios.patch(
        `/tour/edit/${id}`,
        JSON.stringify(editTourData),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.accessToken}`,
          },
          withCredentials: true,
        }
      );
     // console.log(editTourData);
      setEditTourData({
        title: "",
        location: "",
        duration: "",
        included: "",
        imageUrl: "",
        description: "",
        recommendation: "",
        unitPrice: "",
        privatePrice: "",
        active: "",
      });
      const allTours = [...tours, response.data];
      setTours(allTours);
      refetchData(dataUrl);
      navigate("/", { replace: true }); // change to dashboard
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  };

  return (
    <section className="EditTour">
      {editTourData.id && (
        <>
        <div className="mainPanel">

        
          <h3 className="h3Price">Edit Tour</h3>
          <form className="editTourForm" onSubmit={handleEditSubmit}>
            <dir className="buttonContainer">
              <label className="labelSelect" htmlFor="title">
                Title
                <input
                  name="title"
                  type="text"
                  required
                  placeholder="Tour Title"
                  autocomplete="off"
                  value={editTourData.title}
                  onChange={handleChange}
                />
              </label>
              <label className="labelSelect" htmlFor="location">
                Location
                <input
                  name="location"
                  type="text"
                  required
                  autocomplete="off"
                  placeholder="Tour Location"
                  value={editTourData.location}
                  onChange={handleChange}
                />
              </label>

              <label className="labelSelect" htmlFor="imageUrl">
                ImageUrl
                <input
                  name="imageUrl"
                  type="text"
                  required
                  autocomplete="off"
                  placeholder="Image URL"
                  value={editTourData.imageUrl}
                  onChange={handleChange}
                />
              </label>
            </dir>
            <label className="labelTextarea" htmlFor="description">
              Description
              <textarea
                name="description"
                required
                autocomplete="off"
                placeholder="Tour Description..."
                value={editTourData.description}
                onChange={handleChange}
              />
            </label>

            <label className="labelTextarea" htmlFor="included">
             Included
              <textarea
                name="included"
                required
                autocomplete="off"
                placeholder="Included:"
                value={editTourData.included}
                onChange={handleChange}
              />
            </label>
            <label className="labelTextarea" htmlFor="recommendation">
              Recommendations:
              <textarea
                name="recommendation"
                required
                autocomplete="off"
                placeholder="Tour Recommendations..."
                value={editTourData.recommendation}
                onChange={handleChange}
              />
            </label>

            <div className="buttonContainer">
              <label htmlFor="unitPrice" className="labelSelect">
                Set Public Price: $
                <div>
                  <input
                    name="unitPrice"
                    type="number"
                    required
                    min={1}
                    value={editTourData.unitPrice}
                    onChange={handleChange}
                  />
                </div>
              </label>

              <label htmlFor="privatePrice" className="labelSelect">
                Set Private Price: $
                <div>
                  <input
                    name="privatePrice"
                    type="number"
                    required
                    min={1}
                    value={editTourData.privatePrice}
                    onChange={handleChange}
                  />
                </div>
              </label>
            </div>
            <div className="buttonContainer">
              <label htmlFor="duration" className="labelSelect">
                Set Duration (hours): 
                <div>
                  <input
                    name="duration"
                    type="number"
                    required
                    min={1}
                    value={editTourData.duration}
                    onChange={handleChange}
                  />
                </div>
              </label>
              <label htmlFor="active" className="labelSelect">
                State:
                <select
                  value={editTourData.active}
                  onChange={handleChange}
                  name="active"
                  id="active"
                >
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </label>
            </div>
            <div className="buttonContainer">
              <button className="saveButton">Save</button>

              <button type="button" className="backButton" onClick={goBack}>
                Go Back
              </button>
            </div>
          </form>
          </div>
        </>
      )}
      {!editTourData.id && <Missing />}
    </section>
  );
};

export default EditTour;
