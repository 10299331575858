import Tour from "./Tour"

const FeedTour = ({ tours }) => {
  return (
    <>
    {tours.map(tour=>(
      <Tour key={tour._id} tour={tour}/>
    ))}
    </>

  )
}

export default FeedTour