import { Link } from "react-router-dom";
const About = () => {
  return (
    <section className="About">
      <div className="mainPanel">


      <h3 className="h3Price">About</h3>
      <br />

      <div className="textContainer">
        <h3>Who we are?</h3>
        <p>
          We are a micro tour operator, based in Quepos, Puntarenas, focus on
          recreational, ecological and educational tourism, also a
          transportation service provider. <br />
          With 12 years of experience offering activities to worldwide and local
          travelers responsibly.
        </p>

        <h3>Mission</h3>

        <p>
          Our mission is to consolidate ourselves as a local opportunity who
          offers the option to discover our country in a safe, sustainable and
          aware form. <br />
          Incentivize our eco-friendly and sustainable country model, in such a
          way their vacations turn into a memorable and unique experience.
        </p>

        <h3>Vision</h3>

        <p>
          See us as an option for travelers to make them enjoy their vacations,
          so that, the only thought is to remember, recommend and repeat the
          experience.
          <br />
          Growing parallel to the local economy and incentive a competition for
          a better-quality product towards the tourist.
        </p>
        <br />
      </div>

      <h3 className="h3Price">Staff</h3>

      <div className="textContainer">
        <h3>Berny Retana</h3>

        <p>
          Berny is a Nature Tour Guide formed by the Costa Rican National
          Institute for Learning, in its technical carriers for tourism.
          <br />
          With more than 12 years of experience in tourist management and tour
          driving, he has acquired all the knowledge necessary to announce
          important tourist and cultural interest places in its country.
          <br />
          <br />
          Native from the northern side of the country, he moved to the Costa
          Rican Pacific to achieve its studies and formation as a tour guide,
          using academic platforms provided by the Government of Costa Rica used
          to foment entrepreneurship. He has opted to stablish a tour company
          based in Quepos, Puntarenas, with the idea of diversified activities
          for locals and international travelers.
          <br />
          Berny has Tour Guide License extended by the Costa Rican Tourism Board
          (ICT) number 195.
        </p>

        <br />
        <h3>Michelle Salazar</h3>

        <p>
          Michelle Salazar it is a graduated Tour Guide from Costa Rican
          National Institute for Learning.
          <br />
          She has more than 4 years of experience attending and guiding
          tourists, also in customer service.
          <br />
          From Perez Zeledón, she decided to move to Central Pacific with the
          idea of starting in ecologic tourism and gathering experience to apply
          acknowledge obtained in La Amistad Pacifico Conservation Area.
          <br />
          <br />
          Michelle combines its passion for animals with her outstanding talent
          in the painting art, where in her time off, she is dedicated in
          realistic oil and acrylic painting.
          <br />
          Michelle has Tour Guide License extended by the Costa Rican Tourism
          Board (ICT) number 1734
        </p>

        <br />
        </div>
        <h3 className="h3Price">Company Structure</h3>
        <div className="textContainer">
          <br />
        <p>
          Among the assets, we have the latest technology equipment and
          cutting-edge optical quality, to offer an unmatched experience, giving
          to customers the possibility to bring home a wonderful experience of
          our activities.
          <br />
          Another important asset is our modern Van with capacity for 15
          passengers, with all the necessary requirements so that our clients
          can have an extremely pleasant experience. It has all the comfort to
          make long-distance trips, as well as the transportation of our own
          activities and with this, provide a memorable service.
          <br />
          <br />
          We have procedures for civil protection and occupational risk
          policies, in addition to policies for land transportation of tourists,
          this with the idea of offering responsible tourism to travelers.
          <br />
          <br />
          Our operational structure is based on the use of the region's own
          resources, such as suppliers and labor, all with their respective
          responsibilities up to date. We choose to give the possibility of
          growth to people involved in the same sustainable tourism activity.
        </p>

        <br />
        </div>
        <h3 className="h3Price">Offer</h3>
        <div className="textContainer">
          <br />
        <p>
          Our service is always focused on showing the natural resources of our
          country in a sustainable and balanced way with the environment.
          <br />
          <br />
          Our objective is to show the least developed tourist attractions of
          our country as well as the important points of tourist interest in
          Costa Rica.
          <br />
          <br />
          Show to the visitor the true essence of Costa Rica from within and
          improve the traveler's experience in order to maximize their vacations
          in places that are little exploited by tourists.
        </p>

        <br />
        </div>
                  <h3 className="h3Price">Tours</h3>
        <div className="textContainer">
        <div className="buttonContainer">


          <Link to="/tour/6562d71bc78f8ad4e56437ab">
            <p>Manuel Antonio National Park</p>
          </Link>
          <br />
          <Link to="/tour/6562d975c78f8ad4e56437ac">
            <p>Night Tour</p>
          </Link>
          <br />
          <Link to="/tour/6562db8ec78f8ad4e56437ad">
            <p>Rainmaker Park</p>
          </Link>
          <br />
          <Link to="/tour/6562dd58c78f8ad4e56437ae">
            <p>Nauyaca Waterfalls</p>
          </Link>
          <br />
          <Link to="/tour/6562de78c78f8ad4e56437af">
            <p>Mangrove Boat Tour</p>
          </Link>
          <br />
          <Link to="/tour/6562e092c78f8ad4e56437b0">
            <p>Birdwatching</p>
          </Link>
        </div>
      </div>
      </div>
    </section>
  );
};

export default About;
