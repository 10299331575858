
import { Link } from "react-router-dom"
const Header = () => {
  return (
    <header className="Header">
    <Link to="/"> <img src="../img/AdvenTour.png" alt="" />   </Link>  
    <div className="rowButtonContainer"> <h1 className="h1-header"> Manuel Antonio</h1> 
    <h1 className="h1-header"> AdvenTour</h1> 
  </div>  
    </header>
  )
}
export default Header
