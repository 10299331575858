import useAuth from "../hooks/useAuth";
import ReservationModal from "./ReservationModal";
import { useState } from "react";

const Reservation = ({ reservation, reservations, setReservations, orderStatus }) => {
  const { auth } = useAuth();
  const [openModal, setOpenModal] = useState(false);
const handleSelect=()=>{
setOpenModal(true)
}

  return (
    <>
      {auth.username && reservation.orderStatus === orderStatus && (
        <tr className="reservation" onClick={handleSelect}>
          <td data-cell="Created At">{reservation.createdAt}</td>
           <td data-cell="Status">{reservation.tourStatus}</td>
           <td data-cell="Tour">{reservation.tour}</td>      
           <td data-cell="Tour Date">{reservation.tourDate}</td>           
           <td data-cell="Service">{reservation.tourType}</td>        
          <td data-cell="Client">{reservation.payer}</td>          
          <td data-cell="Pax">{reservation.tourist.length}</td>
          <td data-cell="Total $">{reservation.totalPrice}</td>
        </tr>
      )}
        { openModal && (<ReservationModal reservation={reservation} 
        setOpenModal={setOpenModal} 
        reservations ={reservations} setReservations={setReservations}/>)}

      {!reservation && (
        <>
          <h2>Reservation not found</h2>
        </>
      )}
    </>
  );
};

export default Reservation;