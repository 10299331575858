import Reservation from "./Reservation";

const FeedReservation = ({ reservations, setReservations, orderStatus }) => {
  return (
    <>
      <div className="tableContainer">
        <table>
          <thead>
            <tr>
            <th>Created At</th>
              <th>Status</th>
              <th>Tour</th> 
              <th>Tour Date</th> 
              <th>Service</th> 
              <th>Client</th>
              <th>Pax</th>
              <th>Total $</th>
            </tr>
          </thead>
          <tbody>
            {reservations.map((reservation) => (
              <Reservation
                key={reservation.orderId}
                reservation={reservation}
                reservations={reservations}
                setReservations={setReservations}
                orderStatus={orderStatus}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FeedReservation;
