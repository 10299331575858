import axios from "../api/axios";
import { Countries } from "../data/Countries";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import Missing from "./Missing";

const Booking = ({ tours }) => {
  let date = new Date();
  let day = date.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  let year = date.getUTCFullYear();
  let today = `${year}-${month}-${day + 1}`;
  let maxDate = `${year + 1}-${month}-${day}`;

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  const countries = [...Countries];
  const { id } = useParams();
  const tour = tours.find((tour) => tour._id.toString() === id);
  const [price, setPrice] = useState(0);
  const [touristList, setTouristList] = useState([
    {
      client: "",
      clientId: "",
      country: "",
      phone: "",
      email: "",
    },
  ]);
  const [formData, setFormData] = useState({
    tourStatus: "New",
    tour: "",
    location: "",
    tourDate: "",
    tourType: "",
    tourist: [],
    unitPrice: "",
    totalPrice: "",
  });
 
  const taxFee = price * touristList.length * 0.1;
  const total = price * touristList.length + taxFee;

  const handleAddTourist = () => {
    setTouristList([
      ...touristList,
      {
        client: "",
        clientId: "",
        country: "",
        phone: "",
        email: "",
      },
    ]);
  };

  const handleRemoveTourist = (index) => {
    const tempList = [...touristList];
    tempList.splice(index, 1);
    setTouristList(tempList);
    setFormData({
      ...formData,
      tourist: tempList,
    });
  };

  const handleTouristChange = (e, index) => {
    const { name, value } = e.target;
    const tempList = [...touristList];
    tempList[index][name] = value;
    setTouristList(tempList);
    setFormData({
      ...formData,
      tourist: touristList,
    });
    //console.log("touristChange", formData);
  };

  const handleDateChange = (e) => {
    day = new Date(e.target.value).getDay();
    if(day === 1 && formData.tour === "Manuel Antonio National Park"){
      alert("The park is closed on tuesday, please select another date");
      setFormData({
        ...formData,
        tourDate: "",
      });
    }
    else{
          setFormData({
      ...formData,
      tourDate: e.target.value,
    });
    //console.log("handleDateChange", formData);
    }

  };

  const handleTypeChange = (e) => {
    if (e.target.value === "private") {
      setPrice(tour?.privatePrice);
      setFormData({
        ...formData,
        tourType: "private",
        unitPrice: tour.privatePrice,
      });
    }
    if (e.target.value === "regular") {
      setPrice(tour?.unitPrice);
      setFormData({
        ...formData,
        tourType: "regular",
        unitPrice: tour.unitPrice,
      });
      //console.log("handleTypeChange", formData);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // console.log("preTry: ", formData);
    try {
      console.log("prePosts: ", formData);
      const response = await axios.post(
        "payment/create-order",
        JSON.stringify(formData),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setFormData({
        tour: "",
        location: "",
        tourDate: "",
        tourist: "[]",
        unitPrice: 0,
        totalPrice: 0,
      });
      const link = response.data.links[1];
      window.location.replace(`${link?.href}`);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    try {
      if (tour) {
        setFormData({
          ...formData,
          tour: tour?.title,
          location: tour?.location,
          totalPrice: total,
        });
      }
    } catch (err) {
      console.error(err);
    }
  }, [tour, total, setFormData]);

  return (
    <section className="Booking">
      {tour?._id && (
        <>
          <div className="mainPanel">
            <h2 className="h2Home">Booking: {tour?.title}</h2>

            <form className="bookingForm" onSubmit={handleFormSubmit}>
              {touristList.map((tourist, index) => (
                <div className="touristDataForm" key={index}>
                  <strong>{index + 1} Pax - Tourist Information:</strong>
                  <div className="buttonContainer">
                    <label className="hiddenLabel" htmlFor="client">
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="client"
                      id="client"
                      required
                      minLength="9"
                      maxLength="50"
                      autoComplete="off"
                      placeholder="Full Name"
                      value={tourist.client}
                      onChange={(e) => handleTouristChange(e, index)}
                    />

                    <label className="hiddenLabel" htmlFor="clientId">
                      ID / Passport
                    </label>
                    <input
                      type="text"
                      name="clientId"
                      id="clientId"
                      required
                      minLength="8"
                      maxLength="20"
                      autoComplete="off"
                      placeholder="ID / Passport"
                      value={tourist.clientId}
                      onChange={(e) => handleTouristChange(e, index)}
                    />
                  </div>

                  <div className="buttonContainer">
                    <label className="hiddenLabel" htmlFor="phone">
                      Phone Number
                    </label>
                    <input
                      type="number"
                      name="phone"
                      id="phone"
                      inputMode="numeric"
                      min="-99999999"
                      maxLength="15"
                      pattern="[0-9]+"
                      autoComplete="off"
                      required
                      placeholder="Phone Number"
                      value={tourist.phone}
                      onChange={(e) => handleTouristChange(e, index)}
                    />

                    <label className="hiddenLabel" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      required
                      minLength="9"
                      maxLength="30"
                      autoComplete="off"
                      placeholder="Email"
                      value={tourist.email}
                      onChange={(e) => handleTouristChange(e, index)}
                    />
                  </div>

                  <div className="buttonContainer">
                    <label htmlFor="country" className="labelSelect">
                      Country:
                      <select
                        value={tourist.country}
                        id="country"
                        name="country"
                        required
                        onChange={(e) => handleTouristChange(e, index)}
                      >
                        <option value="" disabled defaultValue={""}>
                          Select...
                        </option>

                        {countries.map((country) => {
                          return (
                            <option key={country.value} value={country.name}>
                              {country.name}
                            </option>
                          );
                        })}
                      </select>
                    </label>
                        <label className="labelSelect" htmlFor="button"> Pax Amount
                   <div className="rowButtonContainer">

        
                    {touristList.length - 1 === index && (
                      <button
                        className="buyButton"
                        type="button"
                        onClick={handleAddTourist}
                      >
                        Add Pax
                      </button>
                    )}
                    <br />

                    {touristList.length > 1 && (
                      <button
                        className="backButton"
                        type="button"
                        onClick={() => handleRemoveTourist(index)}
                      >
                        Remove
                      </button>
                    )}           </div>
                    </label>
                  </div>
                </div>
              ))}
              <br />
              <div className="buttonContainer">
                <label htmlFor="tourDate" className="labelSelect">
                  Set Tour Date:
                  <input
                    id="tourDate"
                    type="date"
                    name="tourDate"
                    value={formData?.tourDate}
                    required
                    min={today}
                    max={maxDate}
                    onChange={handleDateChange}
                  />
                </label>

                <label htmlFor="tourType" className="labelSelect">
                  Service Type:
                  <select
                    value={formData.tourType}
                    onChange={handleTypeChange}
                    required
                    name="tourType"
                    id="tourType"
                  >
                    <option value="" disabled defaultValue={""}>
                      Select...
                    </option>
                    <option value="regular">Regular (${tour.unitPrice})</option>
                    <option value="private">
                      Private (${tour.privatePrice})
                    </option>
                  </select>
                </label>
              </div>

              <div className="buttonContainer">
                <h3 className="h3Price">Pax Amount: {touristList.length}</h3>
                <h3 className="h3Price">Total Price: ${total}</h3>
              </div>
              <div className="buttonContainer">
              <p>Tax Fee Included, you will be redirected to PayPal</p>
   
            </div>
              <div className="buttonContainer">
                <button type="submit" className="buyButton">
                  Confirm
                </button>
                <button type="button" onClick={goBack} className="backButton">
                  Go Back
                </button>
              </div>
            </form>
            <br />

          </div>
        </>
      )}
      {!tour?._id && <Missing />}
    </section>
  );
};

export default Booking;
