import { Link, useLocation } from "react-router-dom";
const Footer = () => {
  const today = new Date();
  const location = useLocation().pathname;
  return (
    <footer className="Footer">
      <div className="buttonContainer">
        {location !== "/" && (
          <>
            <Link to="/">
              <p>Back to Home</p>{" "}
            </Link>
            <br />
          </>
        )}
        {location !== "/about" && (
          <>
            <Link to="/about">
              <p>About</p>{" "}
            </Link>
            <br />
          </>
        )}
        {location !== "/terms" && (
          <>
            <Link to="/terms">
              <p>Terms</p>{" "}
            </Link>
            <br />
          </>
        )}
        {location !== "/contact" && (
          <>
            <Link to="/contact">
              <p>Contact</p>{" "}
            </Link>
            <br />
          </>
        )}
      </div>
      <p>Copyright &copy; {today.getFullYear()}</p>
    </footer>
  );
};

export default Footer;
