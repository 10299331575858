import { useState, useEffect } from "react";
import axios from "axios";
import useAuth from "./useAuth";

const useAxiosFetch = (dataUrl) => {
  const {auth} = useAuth();
  const [data, setData] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  useEffect(() => {
    const fetchData = async (url) => {
      setIsLoading(true);
      try {
        const response = await axios.get(url,{
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.accessToken}`,
          },
          withCredentials: true,
        });
        setData(response.data);
        setFetchError(null);
      } catch (err) {
        setFetchError(err.message);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData(dataUrl);
  }, [dataUrl, auth?.accessToken]);

  const refetchData = async (url) => {
    setIsLoading(true);
    try {
      const response = await axios.get(url,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        withCredentials: true,
      });

      setData(response.data);
      setFetchError(null);
    } catch (err) {
      setFetchError(err.message);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, fetchError, isLoading, refetchData };
};

export default useAxiosFetch;
