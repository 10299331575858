import { useNavigate } from "react-router-dom"
const Unauthorized = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate("/", {replace: "true"});
      };
    return (
    <section className="Unauthorized">
        <h1>Unauthorized</h1>
 
            <button type="button" className="backButton" onClick={goBack} >Go Back</button>

    </section>
  )
}

export default Unauthorized