import { useNavigate } from "react-router-dom";

const Acknowledgement = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/", {replace:"true"} );
  };

  return (
    <section className="Acknowledgement">
      <h1>Thanks For Choosing Us</h1>
      <div className="emptySpace"></div>      
      <button type="button" className="buyButton" onClick={goHome}>
        Explore Tours
      </button>
      <h4>Check Inbox for invoicing.</h4>

    </section>
  );
};

export default Acknowledgement;
